<template>
  <div class="about-us">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ title }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <div
        class="banner"
        :style="'backgroundImage:url(' + firmInfo.CompanyPresentationImg + ');'"
      ></div>
      <div class="x-center">
        <h1>关于我们</h1>
        <h2>About Us</h2>
      </div>
      <p>
        <span class="theme-color">{{ firmInfo.CompanyName }}</span>
        {{ firmInfo.CompanyResume }}
      </p>
      <div class="x-center">
        <h1>公司宗旨</h1>
        <h2>Our Mission</h2>
      </div>
      <p>{{ firmInfo.CompanyMission }}</p>
      <div class="x-center">
        <h1>公司愿景</h1>
        <h2>Our Vision</h2>
      </div>
      <p>{{ firmInfo.CompanyVision }}</p>
      <div class="x-center">
        <h1>社会责任</h1>
        <h2>Social Obligation</h2>
      </div>
      <p>{{ firmInfo.CompanyObligation }}</p>
      <div class="x-center">
        <h1>联系我们</h1>
        <h2>Contact US</h2>
      </div>
      <p>联系人：{{ firmInfo.CompanyLinkMan }}</p>
      <p>
        联系电话：{{ firmInfo.CompanyTel }}，手机：{{ firmInfo.CompanyPhone }}
      </p>
      <p>公司地址：{{ firmInfo.CompanyAddr }}</p>
    </main>
  </div>
</template>
<script>
import { APP_TITLE, BASE_URL } from '@/api/index.js';
import { getFirmInfo } from '@/api/website.js';

export default {
  data() {
    return {
      title: APP_TITLE,
      firmInfo: null,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      getFirmInfo().then(res => {
        if (res.Result == true) {
          this.firmInfo = res.DataDict['data'];
          if (!this.firmInfo.CompanyPresentationImg.startsWith('http')) {
            this.firmInfo.CompanyPresentationImg =
              BASE_URL + this.firmInfo.CompanyPresentationImg;
          }
          console.log(this.firmInfo);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';

.banner {
  height: rem(240px);
  margin-bottom: rem(40px);
  background-color: $theme-color;
  background-size: cover;
}
.page-body {
  padding: rem(40px);
  background: #fff;
}
h1 {
  font-size: 16px;
}
h2 {
  font-size: 12px;
  color: #b8b8b8;
}
p {
  line-height: 1.5;
}
</style>
